import { getInstance } from "./auth";

export const authGuard = async (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    if (authService.isAuthenticated) {
      return next();
    } else {
      return next({ name: "Login" });
    }
  };

  if (authService.isAuthenticated) {
    return await fn();
  }

  if (!(await authService.identify())) {
    return next({ name: "Login" });
  } else {
    return await fn();
  }
};
