import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { AuthPlugin } from "./auth/auth";
import { BootstrapVue } from "bootstrap-vue";

import "@tabler/core/dist/css/tabler.css";
import "@tabler/core/dist/js/tabler.js";
import "@tabler/core/dist/css/tabler-payments.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueApexCharts from "vue-apexcharts";
import AsyncComputed from "vue-async-computed";
import VueCardFormat from "vue-credit-card-validation";
import VueGtag from "vue-gtag";

import Vuelidate from "vuelidate";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import VueTour from "vue-tour";
import VueCalendly from "vue-calendly";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);
Vue.use(
  VueGtag,
  {
    config: { id: "G-Z6W7CE980G" },
    enabled: process.env.VUE_APP_ANALYTICS === "true",
  },
  router
);
Vue.use(AuthPlugin, { router: router });
Vue.use(VueApexCharts);
Vue.use(AsyncComputed);
Vue.use(BootstrapVue);
Vue.use(VueCardFormat);
Vue.use(Vuelidate);
Vue.use(VueCalendly);

Vue.component("apexchart", VueApexCharts);

if (process.env.NODE_ENV == "development") {
  Vue.config.productionTip = false;
} else {
  Vue.config.productionTip = true;
}

if (process.env.VUE_APP_USE_SENTRY === "true") {
  Sentry.init({
    Vue,
    dsn: "https://80dc979ac584408e97233f9ceee3366f@o522215.ingest.sentry.io/5999352",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["mac.mediabrains.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  });
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
