import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  identify(user_id, traits) {
    const authService = getInstance();

    if (authService.isAuthenticated && authService.superuser) {
      // don't track demos
      return;
    } else {
      console.log(user_id, traits);
    }
  },

  group() {
    return;
  },

  track(event_name, event_traits) {
    try {
      const authService = getInstance();

      if (!event_traits) {
        event_traits = {};
      }

      if (authService.isAuthenticated && authService.superuser) {
        // don't track demos
        return;
      } else {
        if (authService.isAuthenticated) {
          if (authService.user) {
            event_traits["companyId"] = authService.activeCompany.companyId;
            event_traits["contactId"] = authService.user.contact.contactId;
          } else if (authService.userinfo.contact) {
            if (authService.userinfo.companies.length > 0) {
              event_traits["companyId"] =
                authService.userinfo.companies[0].companyId;
            }
            event_traits["contactId"] = authService.userinfo.contact.contactId;
          }

          if (!event_traits["siteId"] && authService.bestSite) {
            event_traits["siteId"] = authService.bestSite.siteID;
          }
        }

        event_traits["name"] = event_name;
        if (event_traits["companyId"]) {
          event_traits["companyId"] = parseInt(event_traits["companyId"]);
        }

        if (event_traits["contactId"]) {
          event_traits["contactId"] = parseInt(event_traits["contactId"]);
        }

        if (event_traits["siteId"]) {
          event_traits["siteId"] = parseInt(event_traits["siteId"]);
        }

        http.post("api/accountaction/save", event_traits);
      }
    } catch (err) {
      console.error(err);
    }
  },

  trackUnauthenticated(event_name, event_traits) {
    try {
      event_traits["name"] = event_name;
      http.post("api/accountaction/save", event_traits);
    } catch (err) {
      console.error("failed saving event", err);
    }
  },

  page() {
    return;
  },

  pageUnauthenticated() {
    return;
  },
};
