import Vue from 'vue'
import APIService from '../services/APIService'
import TrackingService from '../services/TrackingService'

import { addMinutes, isBefore } from 'date-fns'

let instance;
const http = APIService.http;

export const getInstance = () => instance;

export const useAuth = () => {
  if (instance) return instance;
  instance = new Vue({
    data() {
      let acId = window.localStorage.getItem("activeCompanyId");
      let maskedMode = window.localStorage.getItem("masked-mode");
      let demoActive = window.localStorage.getItem("demoActive");
      if (acId) {
        acId = parseInt(acId);
      } else {
        acId = null;
      }

      if (maskedMode) {
        maskedMode = JSON.parse(maskedMode);
      } else {
        maskedMode = false;
      }

      if (demoActive) {
        demoActive = JSON.parse(demoActive);
      } else {
        demoActive = false;
      }

      return {
        loading: true,
        isAuthenticated: false,
        user: null,
        userinfo: null,
        resetinfo: null,
        demoActive: demoActive,
        token: null,
        loginlink: null,
        token_expires: new Date(),
        activeCompanyId: acId,
        settings: {
          maskedMode: maskedMode,
        },
      };
    },
    watch:{
      demoActive: function (newDemoActive, oldDemoActive) {
        if(newDemoActive !== oldDemoActive) {
          window.localStorage.setItem("demoActive", newDemoActive);
        }
      }
    },
    computed: {
      ignoreDemo() {
        return {
          isFeatured: this.activeCompany.isFeatured,
          featuredSites: new Set(
            this.activeCompany.sites
              .filter((s) => s.listingType == "featured")
              .map((s) => s.siteID)
          ),
        };
      },
      superuser() {
        return this.user && this.user.isDemo;
      },
      can_demo() {
        return this.user && this.user.isDemo;
      },
      bestSite() {
        if (!this.activeCompany) {
          return null;
        }

        if (this.activeCompany.sites.length == 0) {
          return null;
        }

        let sites = [...this.activeCompany.sites];
        return sites.sort((a, b) => a.siteRank - b.siteRank)[0];
      },
      highestPlan() {
        if (!this.activeCompany) {
          return "free";
        }
        if (this.demoActive) return "featured";
        let values = {
          featured: 2,
          optimized: 1,
          free: 0,
        };
        let plans = this.activeCompany.sites.map((s) => s.listingType);
        return plans.sort((a, b) => values[b] - values[a])[0];
      },
      isFeatured() {
        if (!this.activeCompany) {
          return false;
        }
        return this.activeCompany.isFeatured || this.demoActive;
      },
      featuredSites() {
        if (!this.activeCompany) {
          return new Set();
        }
        return new Set(
          this.activeCompany.sites
            .filter((s) =>
              this.demoActive ? true : s.listingType == "featured"
            )
            .map((s) => s.siteID)
        );
      },
      featuredPending() {
        if (!this.activeCompany) {
          return false;
        }
        return this.activeCompany.featuredPending;
      },
      companyList() {
        if(this.user && this.user.companies){
          return this.user.companies;
        }
        else return [];
      },
      activeCompany() {
        if (this.activeCompanyId) {
          let activeCompanyIndex = this.companyList.findIndex(
            (c) => c.companyId === this.activeCompanyId
          );
          if(activeCompanyIndex >= 0)
          {
            return this.user.companies[activeCompanyIndex];
          }
        }
        //There is no active company id or is different from the list of companies for this contact
        if (this.user && this.user.companies.length > 0) {
          return this.user.companies[0];
        } else {
          return null;
        }
      },
    },
    methods: {
      planIncludes(feature, companyPlan) {
        let features = {
          free: [],
          optimized: [
            "logo",
            "social-links",
            "dashboard-report",
            "performance-report",
            "rep-mgmt-qr",
            "request-reviews",
            "leads",
          ],
          featured: [
            "logo",
            "tradeshows",
            "clients",
            "social-links",
            "dashboard-report",
            "performance-report",
            "rep-mgmt-qr",
            "reviews-integrations",
            "request-reviews",
            "request-reviews-bulk",
            "leads",
          ],
        };

        if (!companyPlan) {
          return false;
        }
        return features[companyPlan].includes(feature);
      },
      isFeaturedForSite(siteId) {
        return this.featuredSites.has(siteId) || this.demoActive;
      },
      mask(words, replacement) {
        if (this.settings.maskedMode) {
          return replacement;
        }
        return words;
      },
      saveActiveCompany(companyId) {
        if (this.companyList.findIndex((c) => c.companyId == companyId) == -1) {
          console.log("couldn't update");
          return;
        }
        this.activeCompanyId = companyId;
        window.localStorage.setItem("activeCompanyId", companyId);
      },
      async apiAuthConfig() {
        let token = await this.getToken();
        if (!this.isAuthenticated) {
          return {};
        } else {
          return {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        }
      },
      async apiAuthConfigWithCompanyHeader() {
        let token = await this.getToken();
        let companyId = -1;
        if (this.activeCompany?.companyId) {
          companyId = this.activeCompany.companyId;
        }

        if (!this.isAuthenticated) {
          return {};
        } else {
          return {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CO-ID": companyId,
            },
          };
        }
      },
      async apiAuthConfigWithUpload() {
        let token = await this.getToken();

        if (!this.isAuthenticated) {
          return {};
        } else {
          return {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          };
        }
      },
      async getToken(prefetchedToken = null) {
        if (isBefore(new Date(), this.token_expires)) {
          return this.token;
        }

        let token = prefetchedToken;
        if (!prefetchedToken) {
          try {
            let tokenResponse = await http.post("api/authentication/token");
            token = tokenResponse.data["token"];
          } catch {
            this.isAuthenticated = false;
            return;
          }
        }

        this.token = token;
        this.token_expires = addMinutes(new Date(), 2);
        this.isAuthenticated = true;
        return token;
      },
      async sudo(sudoToken) {
        let success = await http.post("api/authentication/sudo", {
          sudoToken: sudoToken,
        });
        window.localStorage.removeItem("activeCompanyId");
        this.activeCompanyId = null;
        if (success.data && success.data.mbSession) {
          window.sessionStorage.setItem(
            "mb_session_token",
            success.data.mbSession
          );
        }
        return success.data;
      },
      updateMaskedMode() {
        this.settings.maskedMode = !this.settings.maskedMode;
        window.localStorage.setItem(
          "masked-mode",
          JSON.stringify(this.settings.maskedMode)
        );
        window.location.reload();
      },
      maskUser() {
        this.user.contact.firstName = this.mask(
          this.user.contact.firstName,
          "John"
        );
        this.user.contact.lastName = this.mask(
          this.user.contact.lastName,
          "Doe"
        );
        this.user.contact.title = this.mask(
          this.user.contact.title,
          "Marketer"
        );
        for (let i = 0; i < this.user.companies.length; i++) {
          this.user.companies[i].companyName = this.mask(
            this.user.companies[i].companyName,
            "Company"
          );
        }
      },
      async clearCache() {
        try {
          let headers = await this.apiAuthConfig();
          await http.get("api/authentication/me", headers);
          await http.post("api/authentication/clearcache", null, headers);
        } catch (e) {
          this.isAuthenticated = false;
          this.user = null;
          return false;
        }
        return true;
      },
      async identify() {
        try {
          let headers = await this.apiAuthConfig();
          let identification = await http.get("api/authentication/me", headers);
          this.isAuthenticated = true;
          this.user = identification.data;
          this.maskUser();
        } catch (e) {
          console.log(e);
          this.isAuthenticated = false;
          this.user = null;
          return false;
        }

        try {
          TrackingService.identify(this.user.contact.contactId, {
            email: this.user.contact.emailAddress,
            firstName: this.user.contact.firstName,
            lastName: this.user.contact.lastName,
            title: this.user.contact.title,
          });
        } catch (e) {
          console.error(e);
        }

        if (this.activeCompany) {
          TrackingService.group(this.activeCompany.companyId, {
            name: this.activeCompany.companyName,
            featuredSites: Array.from(this.featuredSites),
          });
        }

        return true;
      },
      async logout() {
        if (!this.isAuthenticated) {
          return false;
        }
        let handle = await this.apiAuthConfig();
        await http.post("api/authentication/revoke", null, handle);
        this.user = null;
        this.token = null;
        this.token_expires = new Date();
        this.isAuthenticated = false;

        return true;
      },
      async checkemail(email) {
        let userInfo = await http.post("api/authentication/checkemail", {
          emailAddress: email,
        });

        this.userinfo = userInfo.data;

        try {
          TrackingService.identify(userInfo.data.contact.contactId, {
            email: userInfo.data.contact.emailAddress,
            firstName: userInfo.data.contact.firstName,
            lastName: userInfo.data.contact.lastName,
            title: userInfo.data.contact.title,
          });
        } catch (e) {
          console.error(e);
        }

        return true;
      },
      async sendresetemail(email) {
        let userInfo = await http.post("api/authentication/sendresetemail", {
          emailAddress: email,
        });

        this.userinfo = userInfo.data;
        return true;
      },
      async sendloginlink(email, userid) {
        let userInfo = await http.post("api/authentication/sendloginlink/", {
          emailAddress: email,
          userid: userid,
        });

        this.userinfo = userInfo.data;
        return true;
      },
      async getuserbyresettoken(token) {
        let userInfo = await http.post(
          "api/authentication/getuserbyresettoken/",
          {
            resettoken: token,
          }
        );

        this.resetinfo = userInfo.data;
        return userInfo.data;
      },
      async savepassword(userid, password) {
        let response = await http.post("api/authentication/savepassword/", {
          userid: userid,
          password: password,
        });

        return response.data;
      },
      async resendresetemail(contactid, companyid) {
        let response = await http.post("api/authentication/resendresetemail/", {
          contactid: contactid,
          companyid: companyid,
        });

        return response.data;
      },
      async login(email, password) {
        let authResponse = await http.post("api/authentication/authenticate", {
          emailAddress: email,
          password: password,
        });
        this.settings.maskedMode = false;
        window.localStorage.setItem(
          "masked-mode",
          JSON.stringify(this.settings.maskedMode)
        );
        if (authResponse.status != 200) {
          this.isAuthenticated = false;
          return false;
        } else {
          window.localStorage.removeItem("activeCompanyId");
          this.activeCompanyId = null;
          await this.getToken(authResponse.data.token);
          await this.identify();
          return true;
        }
      },
    },
  });

  return instance;
};

export const AuthPlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth(options);
  },
};
