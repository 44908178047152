import Vue from "vue";
import VueRouter from "vue-router";

import { authGuard } from "../auth/authGuard";
import { getInstance } from "../auth/auth";

import TrackingService from "../services/TrackingService";

import * as Sentry from "@sentry/vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../Layouts/AppLayout.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "login",
        name: "Login",
        component: () => import("../views/Login.vue"),
      },
      {
        path: "sudo",
        name: "Sudo",
        component: () => import("../views/Sudo.vue"),
      },
      {
        path: "leads",
        name: "Leads",
        component: () => import("../views/Leads.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "pressreleases",
        name: "PressReleases",
        component: () => import("../views/PressReleases.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "company",
        name: "Company",
        component: { render: (h) => h("router-view") },
        beforeEnter: authGuard,
        children: [
          {
            path: "qr-codes",
            name: "QRCodes",
            component: () => import("../views/Profile/QRCodes.vue"),
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: "content",
        component: { render: (h) => h("router-view") },
        beforeEnter: authGuard,
        children: [
          {
            path: "",
            name: "ContentLib",
            component: () => import("../views/Content/Content.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "edit",
            name: "Content",
            component: () => import("../views/Content/NewContent.vue"),
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: "products",
        component: { render: (h) => h("router-view") },
        beforeEnter: authGuard,
        children: [
          {
            path: "",
            name: "products",
            component: () => import("../views/Products.vue"),
            props: true,
            beforeEnter: authGuard,
          },
          {
            path: "add",
            name: "addproducts",
            component: () => import("../views/Products/AddNewProduct.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "edit",
            name: "Editproducts",
            component: () => import("../views/Products/AddNewProduct.vue"),
            props: true,
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "upgrade",
        name: "Upgrade",
        component: () => import("../views/Upgrade.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "newupgrade",
        name: "newupgrade",
        component: () => import("../views/NewUpgrade.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("../views/Settings.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "resetpassword",
        name: "ResetPassword",
        component: () => import("../views/ResetPassword.vue"),
      },
      {
        path: "verify",
        name: "verify",
        component: { render: (h) => h("router-view") },
        beforeEnter: authGuard,
        children: [
          {
            path: "thank-you",
            name: "verify-thank-you",
            component: () => import("../views/Verify/ThankYou.vue"),
          },
        ],
      },
      {
        path: "admin",
        name: "admin",
        component: { render: (h) => h("router-view") },
        beforeEnter: authGuard,
        children: [
          {
            path: "insights",
            name: "admin.insights",
            component: () => import("../views/Admin/Insights.vue"),
          },
          {
            path: "subscriptions",
            name: "admin.subscriptions",
            component: () => import("../views/Admin/RecurringBilling.vue"),
          },
          {
            path: "report",
            name: "admin.report",
            component: { render: (h) => h("router-view") },
            children: [
              {
                path: "audience",
                name: "admin.report.audience",
                component: () => import("../views/Admin/AudienceReport.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "add-your-company",
        name: "AddYourCompany",
        component: () => import("../views/AddYourCompany/Index.vue"),
      },
      {
        path: "reports",
        name: "reports",
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "visitor-insight-report",
            name: "reports.visitor-insight",
            beforeEnter: authGuard,
            component: () =>
              import("../views/Reports/VisitorInsightReport.vue"),
          },
          {
            path: "graphical-ad-report",
            name: "reports.graphical-ad",
            beforeEnter: authGuard,
            component: () => import("../views/Reports/GraphicalAdReport.vue"),
          },
          {
            path: "performance-report",
            name: "reports.performance",
            beforeEnter: authGuard,
            component: () => import("../views/Reports/PerformanceReport.vue"),
          },
          {
            path: "audience-report",
            name: "reports.audience",
            component: () => import("../views/Reports/AudienceReport.vue"),
          },
        ],
      },
      {
        path: "account",
        component: () => import("../views/Account/Index.vue"),
        beforeEnter: authGuard,
        children: [
          {
            path: "",
            name: "account.index",
            component: () =>
              import("../components/Account/Profile/Profile.vue"),
          },
          {
            path: "invoices",
            name: "account.invoices",
            component: () =>
              import("../components/Account/Billing/Invoices.vue"),
          },
          {
            path: "plan",
            name: "account.plan",
            component: () => import("../components/Account/Billing/Plan.vue"),
          },
          {
            path: "billing",
            name: "account.billing",
            component: () =>
              import("../components/Account/Billing/Billing.vue"),
          },
        ],
      },
      {
        path: "reputation",
        component: () => import("../views/Reputation/Index.vue"),
        beforeEnter: authGuard,
        children: [
          {
            path: "dashboard",
            name: "reputation.index",
            component: () => import("../components/Reputation/Dashboard.vue"),
          },
          {
            path: "integrations",
            name: "reputation.integrations",
            component: () =>
              import("../components/Reputation/Integrations.vue"),
          },
          {
            path: "",
            name: "reputation.get-reviews",
            component: () =>
              import("../components/Reputation/CustomerSurvey.vue"),
          },
          {
            path: "review-flows",
            name: "reputation.review-flows",
            component: () => import("../components/Reputation/ReviewFlows.vue"),
          },
          {
            path: "review-flow/new",
            name: "reputation.create-review-flow",
            component: () =>
              import("../components/Reputation/CreateReviewFlow.vue"),
          },
          {
            path: "review-flow/:id",
            name: "reputation.review-flow-detail",
            component: () =>
              import("../components/Reputation/ReviewFlowDetail.vue"),
          },
          {
            path: "review-flow/:review_flow_id/cohort/:id",
            name: "reputation.cohort-detail",
            component: () =>
              import("../components/Reputation/CohortDetail.vue"),
          },
          {
            path: "templates",
            name: "reputation.templates",
            component: () => import("../components/Reputation/Templates.vue"),
          },
          {
            path: "template/:privateKey",
            name: "reputation.template-detail",
            component: () =>
              import("../components/Reputation/TemplateDetail.vue"),
          },
          {
            path: "workflows",
            name: "reputation.workflows",
            component: () => import("../components/Reputation/Workflows.vue"),
          },
          {
            path: "workflow/new",
            name: "reputation.new-workflow",
            component: () =>
              import("../components/Reputation/EditWorkflow.vue"),
          },
          {
            path: "workflow/:id",
            name: "reputation.workflow-detail",
            component: () =>
              import("../components/Reputation/WorkflowDetail.vue"),
          },
          {
            path: "search-score",
            name: "reputation.search-score",
            component: () => import("../components/Reputation/SearchScore.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/billing",
    name: "billing",
    component: () => import("../Layouts/BillingLayout.vue"),
    children: [
      {
        path: "invoice-detail",
        name: "billing.invoice-detail",
        alias: ["invdtl"],
        component: () => import("../views/Billing/InvoiceDetail.vue"),
      },
      {
        path: "paybycc",
        name: "billing.paybycc",
        component: () => import("../views/Billing/PayByCC.vue"),
      },
      {
        path: "renewal",
        name: "billing.renewal",
        component: () => import("../views/Billing/Renewal.vue"),
      },
      {
        path: "paybycheck",
        name: "billing.paybycheck",
        component: () => import("../views/Billing/PayByCheck.vue"),
      },
    ],
  },
  {
    path: "/redirect",
    component: () => import("../Layouts/BillingLayout.vue"),
    children: [
      {
        path: "",
        name: "Redirect",
        component: () => import("../views/Redirect.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick().then(() => {
    const authService = getInstance();

    if (authService.isAuthenticated) {
      try {
        Sentry.setUser({ email: authService.user.contact.emailAddress });
      } catch {
        console.log("error");
      }
    }

    if (authService.isAuthenticated && authService.superuser) {
      // don't track demos
      return;
    } else if (authService.isAuthenticated) {
      TrackingService.page(to.name);
    }
  });
});

export default router;
